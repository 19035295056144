section.final-report {

    .avatar-label-xl {
        font-size: 1.2em;
    }
    
    .sameVote {
        // padding-left: 28px;
        margin: 10px 0;
    }

    .sameVoteLabel {
        display: inline-block;
        font-size: 0.85em;
        color: #777777;
    }

    .matches {

        list-style-type: none;
        padding-left: 0;

        li {
            position: relative;
        }

        .col {
            padding-right: 5px;
            padding-left: 5px;
        }
        .row {
            margin-left: -5px;
        }

        .avatar {
            width: #{$avatar-label-height * $avatar-size-xl-factor};
            height: #{$avatar-label-height * $avatar-size-xl-factor};
        }
        .avatar-label {
            // background: rgba(255,255,255,.7);
            background: none;
            font-size: 1.2em;
            padding-right: #{$avatar-label-height};
            line-height: 100%;
        }
        .percentage {
            font-size: 0.85em;
        }

        .bar {
            position: absolute;
            display: inline-block;
            background: rgba(0, 0, 0, 0.1);
            border-radius: #{$avatar-label-height / 2 * $avatar-size-xl-factor};
            height: #{$avatar-label-height * $avatar-size-xl-factor};

            -webkit-transition: width 2s;
            transition: width 2s;
            z-index: 1;

            top: 50%;
            margin-top: -$avatar-label-height;
        }

        .bar-content {
            position: relative;
            z-index: 2;
        }
    }
}
