@import '~@angular/material/theming';

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

@include mat-core();

$inherited-app-primary: mat-palette($mat-indigo);
$inherited-app-accent:  mat-palette($mat-indigo);
$inherited-app-warn:    mat-palette($mat-red);

$inherited-app-theme: mat-light-theme($inherited-app-primary, $inherited-app-accent, $inherited-app-warn);

@include angular-material-theme($inherited-app-theme);

.mat-chip.mat-standard-chip.question-answered {
    background-color: #e1bee7;
}

.mat-big-button.mat-raised-button {
    font-size: 1.5em;
    line-height: 50px;
    padding: 0 35px;
}

mat-radio-button .mat-radio-label-content {
    white-space: normal;
}
mat-radio-button .mat-radio-label {
    align-items: normal;
}


.mat-title.survey-question {
    line-height: 140%;
    margin-bottom: 16px;

    @include media-breakpoint-only(xs) {
        font-size: 16px;
        font-weight: 500;
    }
    @include media-breakpoint-only(sm) {
        font-size: 18px;
        font-weight: 500;
    }
    @include media-breakpoint-only(md) {
        font-size: 20px;
    }
}
