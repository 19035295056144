html, body { height: 100%; }
body { margin: 0; font-family: 'Roboto', sans-serif; }

body {
    background-color: #eeeeee;
}

.text-faded {
    color: rgba(255,255,255,.8);
    text-shadow: 2px 1px 1px rgba(0,0,0,0.4);
}

.block-center {
    margin-left: auto;
    margin-right: auto;
}

footer {
    margin: 20px 0;
    font-size: 0.8em;

    a {
        border-bottom: 1px dashed rgba(0,0,0,0.1);
    }
}

footer, footer a, footer a:hover {
    color: rgba(0,0,0,0.4);
}
