section.intro {
    min-height: 100vh;
    padding-top: 0;
    padding-bottom: 0;
    background-image: url(assets/images/header.jpg);
    background-position: center center;
    background-size: cover;
    background-color: rgb(74, 66, 63);
    display: flex!important;

    .container {
        margin-top: auto!important;
        margin-bottom: auto!important;
    }

    hr {
        margin-top: 30px;
        margin-bottom: 30px;
        max-width: 50px;
        border-width: 3px;
        border-color: #f05f40;
    }

    
    footer, footer a, footer a:hover {
        color: rgba(255,255,255,0.8);
        text-shadow: 1px 1px rgba(0,0,0,0.3);
    }

    footer {
        width: 100%;
        text-align: center;
        // position: absolute;
        // bottom: 20px;
    }
}
